import React, { useState } from "react"
import VisuallyHidden from "@reach/visually-hidden"

// Sorting tips by amount makes the $1/"Other amount" be first; we want it last
function orderTips(tips) {
  const sortedTips = [...tips].sort((a, b) => a.amount - b.amount)
  return [...sortedTips.slice(1), sortedTips[0]]
}

function TipjarButtons({
  activeTip,
  activeTipBorderColor,
  selectTip,
  tips,
  textColor
}) {
  const [showCustomTipField, setShowCustomTipField] = useState(false)
  const [customTip, setCustomTip] = useState("")

  const orderedTips = orderTips(tips)
  const minimumTip = orderedTips[orderedTips.length - 1]

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: minimumTip.currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  })

  function handleChange(tip) {
    selectTip(tip)

    if (tip.id == minimumTip.id) {
      setShowCustomTipField(true)
      customTip ? setCustomTipAmount(customTip) : null
    } else {
      setShowCustomTipField(false)
    }
  }

  function handleCustomTip(e) {
    const rawTipAmount = e.target.value

    setCustomTipAmount(rawTipAmount)
  }

  function setCustomTipAmount(rawTipAmount) {
    const customTip = {
      id: activeTip.id,
      amount: (parseFloat(rawTipAmount) * 100).toString(),
      currency: activeTip.currency,
      currency_display: activeTip.currency_display,
      formatted: formatter.format(rawTipAmount)
    }

    setCustomTip(rawTipAmount)
    selectTip(customTip)
  }

  return (
    <>
      <fieldset>
        <legend
          style={{
            marginBottom: "1rem",
            fontSize: "1rem",
            fontWeight: "700",
            color: textColor
          }}
        >
          Choose an amount
        </legend>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(6, minmax(0, 1fr))",
            columnGap: ".75rem"
          }}
        >
          {orderedTips.map((tip, idx) => {
            return (
              <label
                htmlFor={tip.id}
                key={tip.id}
                className="flex items-center justify-center text-center border border-solid rounded cursor-pointer"
                style={{
                  color: textColor,
                  fontSize: ".875rem",
                  fontWeight: "700",
                  height: "2.5rem",
                  borderColor: `${
                    activeTip.id === tip.id ? activeTipBorderColor : "#DCE1E5"
                  }`,
                  gridColumn: `${idx === 4 ? "span 2 / span 2" : ""}`
                }}
              >
                {tip.amount == "100.0"
                  ? "Other amount"
                  : tip.formatted.split(".")[0]}

                <input
                  id={tip.id}
                  type="radio"
                  checked={activeTip === tip}
                  onChange={() => handleChange(tip)}
                  value={tip.id}
                  style={{ display: "none" }}
                />
              </label>
            )
          })}
        </div>
      </fieldset>
      {activeTip && showCustomTipField && (
        <div className="flex-row w-full relative">
          <VisuallyHidden htmlFor="customTipInput">
            Set your own tip amount
          </VisuallyHidden>
          <input
            id="customTipInput"
            name="custom_tip_amount"
            style={{ marginTop: "1rem" }}
            type="number"
            placeholder={`Choose a tip of ${minimumTip.formatted} or more`}
            value={customTip}
            onChange={handleCustomTip}
          />
          <div className="bg-gray-200 justify-center items-center flex formkit-input__append">
            {activeTip.currency_display}
          </div>
        </div>
      )}
    </>
  )
}

export default TipjarButtons
