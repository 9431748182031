import React, { useState } from "react"
import PropTypes from "prop-types"

function QuantityInput({ maxQuantity, selectQuantity, styles, textColor }) {
  const [quantity, setQuantity] = useState("1")
  const [error, setError] = useState("")

  const UNLIMITED_QUANTITY = 0

  const handleChange = (e) => {
    const value = e.target.value

    setQuantity(value)

    // If there is any error, show it and revert the quantity to 1.
    // Disabling the buy button is a larger rework for another day.

    let selectedQuantity = parseInt(value)

    if (isNaN(selectedQuantity)) {
      selectedQuantity = 1
    }

    if (selectedQuantity < 1) {
      setError("Ignoring invalid quantity (must be 1 or higher)")
      selectedQuantity = 1
    } else if (
      maxQuantity !== UNLIMITED_QUANTITY &&
      selectedQuantity > maxQuantity
    ) {
      setError(
        `Ignoring invalid quantity (must be between 1 and ${maxQuantity})`
      )
      selectedQuantity = 1
    } else {
      setError("")
    }

    selectQuantity(selectedQuantity)
  }

  return (
    <div>
      <label
        className="formkit-quantity-input___label"
        htmlFor="quantityInput"
        style={{ color: textColor }}
      >
        Quantity
      </label>

      <span
        style={{
          fontFamily: "inherit",
          fontSize: "0.75rem",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "1.5rem",
          paddingLeft: "12px",
          color: "#e4584c"
        }}
      >
        {error}
      </span>

      <div className="flex-row w-full relative">
        <input
          id="quantityInput"
          className="formkit-input formkit-quantity-input"
          name="quantity"
          style={styles.forElement("purchase_input")}
          required={true}
          type="number"
          min="1"
          value={quantity}
          onChange={handleChange}
        />
      </div>
    </div>
  )
}

QuantityInput.propTypes = {
  maxQuantity: PropTypes.number.isRequired,
  selectQuantity: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired
}

export default QuantityInput
