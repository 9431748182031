import React from "react"

// This component will conditionally render elements inside an <a> if they
// have a URL. Used to link images without rendering messy conditionals.
export const LinkWrapper = ({
  url,
  children,
  className = "block",
  ...props
}) => {
  if (url) {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
        {...props}
      >
        {children}
      </a>
    )
  } else {
    return children
  }
}
