export function frequencyTitle(frequency) {
  if (frequency == "month") {
    return "Monthly"
  } else if (frequency == "quarter") {
    return "Quarterly"
  } else {
    return "Yearly"
  }
}

export function formatPrice(amount, currency = "usd") {
  const nonDecimalCurrencies = new Set([
    "bif",
    "clp",
    "djf",
    "gnf",
    "jpy",
    "kmf",
    "krw",
    "mga",
    "pyg",
    "rwf",
    "ugx",
    "vnd",
    "vuv",
    "xaf",
    "xof",
    "xpf"
  ])
  let decimalAmount = amount / 100
  if (nonDecimalCurrencies.has(currency)) {
    decimalAmount = amount
  }
  const formattedPrice = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency
  }).format(decimalAmount)

  return formattedPrice
}

export function discountedPrice(price, coupon, quantity) {
  let priceAmount = price.amount

  if (quantity) {
    priceAmount *= quantity
  }
  const type = coupon.type || coupon.coupon_type
  if (type == "dollars") {
    return Math.max(priceAmount - coupon.amount, 0.0)
  }

  return Math.max(priceAmount - (priceAmount * coupon.amount) / 100.0, 0.0)
}

export function formatCheckout(price, coupon, quantity) {
  if (price.installments) {
    return formatInstallmentsPrice(price, coupon)
  }

  let amount = price.amount

  if (quantity) {
    amount *= quantity
  }

  amount = coupon ? discountedPrice(price, coupon, quantity) : amount
  const frequencyName = frequency(price)

  return `${formatPrice(amount, price.currency)}${
    frequencyName ? ` / ${frequencyName}` : ""
  }`
}

export function totalLineAmount(lines) {
  return lines.reduce((result, line) => {
    return result + line.amount
  }, 0)
}

export function formatInstallmentsDuration(price, coupon) {
  let amount = price.amount

  if (coupon) {
    let installments_price = {
      amount: parseInt(price.amount) / price.installments
    }
    let discounted_amount = discountedPrice(installments_price, coupon)
    amount = discounted_amount * price.installments
  }

  let billingFrequency = frequency(price)
  let interval =
    price.installments > 1 ? `${billingFrequency}s` : billingFrequency
  let formatted_amount = formatPrice(amount, price.currency)

  return `${formatted_amount} over ${price.installments} ${interval}`
}

export function formatInstallmentsPrice(price, coupon) {
  let price_copy = {
    amount: parseInt(price.amount) / price.installments
  }

  let amount = coupon ? discountedPrice(price_copy, coupon) : price_copy.amount
  let formatted_amount = formatPrice(amount, price.currency)

  return `${formatted_amount}/${frequency(price)}`
}

export function frequency(price) {
  if (price.interval) {
    return (
      frequencyForIntervalCount(price) ||
      frequencyFromFormattedPrice(price) ||
      price.interval
    )
  } else {
    return null
  }
}

function frequencyForIntervalCount(price) {
  let intervalMap = {
    "1": "month",
    "3": "quarter",
    "12": "year"
  }

  if (price.interval_count) {
    return intervalMap[price.interval_count.toString()]
  }
}

function frequencyFromFormattedPrice(price) {
  if (price.formatted) {
    return price.formatted.split("/").slice(-1).toString().trim()
  }
}

export function discountExplanationMessage(price, coupon) {
  if (coupon && coupon.duration_in_months) {
    const discount_period = Math.ceil(
      parseInt(coupon.duration_in_months) / price.interval_count
    )

    const discount_explanation_message = `You'll be charged ${formatPrice(
      discountedPrice(price, coupon),
      price.currency
    )} per ${frequency(price)} for the first ${discount_period} ${frequency(
      price,
      price.currency
    )}${discount_period == 1 ? "" : "s"}, then ${formatPrice(
      price.amount,
      price.currency
    )} per ${frequency(price)}.`

    return discount_explanation_message
  } else {
    return null
  }
}
