import React from "react"
import Element from "./element"
import { useElement } from "../landing-page"

function Image({ name, style, className }) {
  const element = useElement(name)

  if (!element.src) return null

  return (
    <Element as="div" name={name} className={`formkit-image ${className}`}>
      <img src={element.src} style={{ ...style, maxWidth: "100%" }} alt={element.alt} />
    </Element>
  )
}

export default Image
