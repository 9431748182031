const capitalize = word => {
  return `${word.slice(0, 1).toUpperCase()}${word.slice(1)}`;
};

const camelize = text => {
  const words = text.split("-");
  return [words[0], words.slice(1).map(word => capitalize(word))].join("");
};

const styleKey = (key, name) => camelize(name.split(`${key}.`)[1]);

const stylesToJs = (key, styles, defaults) => {
  return styles
    .filter(style => style.name.indexOf(`${key}.`) == 0)
    .filter(style =>
      defaults
        ? Object.prototype.hasOwnProperty.call(
            defaults,
            styleKey(key, style.name)
          )
        : true
    )
    .reduce((jsStyles, style) => {
      // const styleKey = camelize(style.name.split(`${key}.`)[1])
      return { ...jsStyles, [styleKey(key, style.name)]: style.value };
    }, {});
};

export default stylesToJs;
