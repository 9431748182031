import React, { useState, useEffect, useRef } from "react"
import { LinkWrapper } from "../../../utils/gallery-utils"

const FEATURED_THUMBNAIL_WIDTH = "150px"
const FEATURED_IMAGE_HEIGHT = "70%"

const FADE_STYLES = {
  fadeLeft: {
    WebkitMaskImage: `-webkit-gradient(linear, left center, right center,
      color-stop(0, transparent),
      color-stop(0.05, rgba(0, 0, 0, .5)),
      color-stop(0.1, black))`
  },
  fadeRight: {
    WebkitMaskImage: `-webkit-gradient(linear, right center, left center,
      color-stop(0, transparent),
      color-stop(0.05, rgba(0, 0, 0, .5)),
      color-stop(0.1, black))`
  },
  fadeBoth: {
    WebkitMaskImage: `-webkit-gradient(linear, left center, right center,
      color-stop(0, transparent),
      color-stop(0.05, rgba(0, 0, 0, .5)),
      color-stop(0.1, black),
      color-stop(0.9, black),
      color-stop(0.95, rgba(0, 0, 0, .5)),
      color-stop(1, transparent))`
  }
}

function FeaturedThumbnail({ src, alt, focus, active, settings: { radius } }) {
  const containerStyles = {
    flex: `0 0 ${FEATURED_THUMBNAIL_WIDTH}`
  }

  const imageStyles = {
    position: "absolute",
    left: 0,
    top: 0,
    objectFit: "cover",
    objectPosition: "center",
    borderRadius: radius
  }
  const imageClasses = `${
    active ? "opacity-100" : "opacity-75"
  } hover:opacity-100 transition-opacity duration-200 h-full w-full`

  return (
    <div className="mr-4" style={containerStyles}>
      <button
        onClick={focus}
        className="block relative w-full h-0 cursor-pointer focus:outline-none focus:gallery border-0"
        style={{ paddingBottom: FEATURED_IMAGE_HEIGHT, background: 0 }}
      >
        <img src={src} alt={alt} style={imageStyles} className={imageClasses} />
      </button>
    </div>
  )
}

function Caption({ text }) {
  if (!text) return null
  return <figcaption className="mt-2 text-center">{text}</figcaption>
}

export default function FeaturedGallery({ images, settings, style }) {
  const [active, setActive] = useState(0)
  const [fadeStyle, setFadeStyle] = useState({})
  const thumbnailContainer = useRef(null)
  const activeImage = images[active]

  const focus = (index) => setActive(index)

  const activeImageStyles = activeImage && {
    position: "absolute",
    left: 0,
    top: 0,
    objectFit: "cover",
    objectPosition: "center",
    borderRadius: settings.radius
  }

  useEffect(() => {
    const container = thumbnailContainer.current

    const handleFade = () => {
      const { scrollLeft, scrollWidth, offsetWidth } = container
      const canScrollLeft = scrollLeft ? true : false
      const canScrollRight = scrollLeft + offsetWidth < scrollWidth

      if (canScrollLeft && canScrollRight) {
        setFadeStyle(FADE_STYLES.fadeBoth)
      } else if (canScrollLeft) {
        setFadeStyle(FADE_STYLES.fadeLeft)
      } else if (canScrollRight) {
        setFadeStyle(FADE_STYLES.fadeRight)
      } else {
        setFadeStyle({})
      }
    }

    handleFade()
    container.addEventListener("scroll", handleFade)
    return () => container.removeEventListener("scroll", handleFade)
  }, [])

  return (
    <div style={style}>
      {activeImage && (
        <figure style={{ margin: 0 }}>
          <LinkWrapper url={activeImage.url}>
            <picture
              className="h-0 relative block"
              style={{ paddingBottom: FEATURED_IMAGE_HEIGHT }}
            >
              <img
                src={activeImage.src}
                alt={activeImage.alt}
                style={activeImageStyles}
                className="h-full w-full"
              />
            </picture>
          </LinkWrapper>
          <Caption text={activeImage.caption} />
        </figure>
      )}
      <div className="relative">
        <div
          className="flex overflow-x-auto pt-4 pb-4"
          style={fadeStyle}
          ref={thumbnailContainer}
        >
          {images.map((image, index) => {
            return (
              <FeaturedThumbnail
                key={image.element_id}
                src={image.src}
                {...image}
                focus={() => focus(index)}
                active={index === active}
                settings={settings}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
