import React, { useLayoutEffect, useRef } from "react"
import Errors from "./errors"
import Button from "./button"
import Content from "./content"
import Heading from "./heading"
import Image from "./image"
import Video from "./video"
import Static from "./static"
import Gallery from "./gallery"
import Price from "./price"
import MultiplePrices from "./multiple-prices"
import PayWhatYouWantInput from "./pay-what-you-want-input"
import QuantityInput from "./quantity-input"
import TipjarButtons from "./tipjar-buttons"
import Region from "./region"
import PoweredByConvertKit from "./powered-by-convertkit"
import BuiltWith from "./built-with"
import { register } from "../../components"
import { useInfo } from "../landing-page"

let registered = false
function registerWithCKJS(data) {
  if (registered) return
  window.__sv_forms = [data]
  registered = true
}

export const Form = ({ style, children, ...props }) => {
  const { id, uid, action, settings } = useInfo()
  const form = useRef()

  useLayoutEffect(() => {
    registerWithCKJS({
      element: form.current,
      id,
      uid,
      template: "",
      settings
    })
  }, [])

  return (
    <form
      ref={form}
      method="POST"
      action={action}
      style={style}
      data-sv-form={id}
      data-uid={uid}
      className="formkit-form"
      {...props}
    >
      {children}
    </form>
  )
}

const LandingPage = ({ children, style, uid }) => {
  return (
    <div className="formkit-page" style={style} data-uid={uid}>
      {children}
    </div>
  )
}
export default LandingPage

register("Form", Form)
register("LandingPage", LandingPage)
register("AddFieldButton", () => null)
register("Button", Button)
register("CustomFields", () => <Static as="div" />)
register("Content", Content)
register("Errors", Errors)
register("Event", () => <Static as="div" />)
register("Heading", Heading)
register("Image", Image)
register("Price", Price)
register("MultiplePrices", MultiplePrices)
register("PayWhatYouWantInput", PayWhatYouWantInput)
register("QuantityInput", QuantityInput)
register("TipjarButtons", TipjarButtons)
register("PoweredByConvertKit", PoweredByConvertKit)
register("BuiltWith", BuiltWith)
register("PoweredBy", () => <Static as="a" />)
register("Video", Video)
register("Gallery", Gallery)
register("Links", () => <Static as="div" />)
register("Icons", () => <Static as="div" />)
register("Region", Region)
register("Feed", () => <Static as="div" />)
