/*
  Inside of a region, some elements of the same `type` should be controlled by
  the same settings. For instance, in the following example...

    <Elements.Region name="foobar">
      <Elements.Content />
      <Elements.Content />
    </Elements.Region>

  ...the `determineGroup()` function below would be responsible for adding each
  of the content elements inside the region to a group called `foobar_text`.
  
  Our sidebar hooks into this group name to present settings, such as font color
  and font weight, depending on the properties listed in the template's settings
  file (*.settings.js).
*/

export default function determineGroup({ region, type, group }) {
  if (!region) return

  const identifier = group || region

  switch (type) {
    case "Text":
      return `${identifier}_text`
    default:
      return
  }
}
