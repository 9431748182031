import React from "react"
import PropTypes from "prop-types"
import { useStyles } from "../landing-page"
import determineGroup from "../../utils/determine-group"

function Element({ as, name, group, children, ...props }) {
  const Component = as
  const styles = useStyles()
  const className = name ? `formkit-${name}` : ""
  const groupProps = {
    region: props.region,
    type: props.type,
    group
  }

  return (
    <Component
      className={className}
      style={styles.forElement(determineGroup(groupProps) || group || name)}
      {...props}
    >
      {children}
    </Component>
  )
}

Element.propTypes = {
  as: PropTypes.string,
  name: PropTypes.string,
  group: PropTypes.string,
  region: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
  dangerouslySetInnerHTML: PropTypes.shape({
    __html: PropTypes.string.isRequired
  }).isRequired
}

export default Element
