import htmlSanitizer from "sanitize-html"
import { unescape } from "lodash"

export const defaultConfig = {
  textFilter: (text) => unescape(text),
  allowedTags: htmlSanitizer.defaults.allowedTags.concat([
    "img",
    "style",
    "video",
    "audio",
    "track",
    "source",
    "details",
    "summary"
  ]),
  allowVulnerableTags: true,
  allowedAttributes: {
    ...htmlSanitizer.defaults.allowedAttributes,
    // iFrames are not allowed by default but when they are allowed, these are the attributes that are allowed
    iframe: [
      "src",
      "width",
      "height",
      "frameborder",
      "allow",
      "allowfullscreen",
      "scrolling"
    ],
    a: [...htmlSanitizer.defaults.allowedAttributes.a, "rel", "trigger-id"],
    video: ["src", "controls", "autoplay", "loop", "muted", "poster"],
    audio: ["src", "controls", "autoplay", "loop", "muted"],
    track: ["src", "label", "kind", "srclang"],
    source: ["src", "type"],
    blockquote: ["cite"],
    details: ["open"],
    time: ["datetime"],
    "*": [
      "style",
      "class",
      "title",
      "id",
      "alt",
      "aria-label",
      "align",
      "valign",
      "border",
      "cellpadding",
      "cellspacing",
      "colspan",
      "rowspan",
      "width",
      "height",
      "data-*",
      "bgcolor",
      "dir",
      "lang",
      "tabindex",
      "translate",
      "spellcheck"
    ]
  }
}
/**
 * Sanitize a string to prevent XSS attacks
 * @param {string} html - The html string to sanitize
 * @param {config} config - The configuration object for the sanitizer
 * @returns {string} The sanitized string
 */
const sanitizeHTML = (html, config) => {
  const options = { ...defaultConfig, ...config }
  // By wrapping the result in unescape, we prevent sanitize-html from converting
  // symbols in attributes to html entities
  return unescape(htmlSanitizer(html, options))
}

export default sanitizeHTML
