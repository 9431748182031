import React from "react"
import { useChildElements } from "../landing-page"
import Static from "./static"
import FeaturedGallery from "./gallery/featured-gallery"

export default function ImageGallery({ element_id, ...props }) {
  const { children } = useChildElements("parent_element_id", element_id)
  const images = children.length ? children : props.defaultChildren || []

  const { layout } = props

  const styles = {
    fontSize: `${props.captionSize}px`,
    fontWeight: props.captionWeight,
    color: props.captionColor
  }

  const galleryProps = {
    images,
    settings: props,
    style: styles
  }

  return (
    <div className="mt-4 mb-4 formkit-gallery">
      {layout === "grid" && <Static as="div" />}
      {layout === "featured" && <FeaturedGallery {...galleryProps} />}
    </div>
  )
}
