// Conditionally adds styles to elements based on their other styles. For
// example, if a user is using a pattern background, we need to set properties
// to size the background so it tiles.
export default function addStyles(selector, styles) {
  const additionalStyles = [];

  const patternBackgrounds = styles.filter(
    ({ name, value }) =>
      name === `${selector}.background-image` && value.match("data:image/svg")
  );

  patternBackgrounds.forEach(() =>
    additionalStyles.push(
      { name: `${selector}.background-size`, value: "auto auto" },
      { name: `${selector}.background-repeat`, value: "repeat" }
    )
  );

  return [...styles, ...additionalStyles];
}
