import React from "react"
import {
  frequencyTitle,
  formatPrice,
  discountedPrice,
  frequency
} from "../../utils/price-functions"
import VisuallyHidden from "@reach/visually-hidden"

function MultiplePrices({
  coupon,
  prices,
  selectPrice,
  activePrice,
  activePriceBorderColor,
  textColor
}) {
  const priceElementStyles = {
    active: {
      margin: "0px 4px",
      minWidth: "126px",
      width: "100%",
      borderColor: activePriceBorderColor
    },
    inactive: {
      margin: "0px 4px",
      minWidth: "126px",
      width: "100%",
      borderColor: "#DCE1E5"
    }
  }

  const priceElementInnerContainerStyles = {
    padding: "12px"
  }

  const priceCrossedOutStyles = {
    color: "#B0B8BE",
    textDecoration: "line-through",
    fontFamily: "inherit",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
    marginRight: "0.25rem"
  }

  const MAX_PRICE_DIGITS_FOR_SINGLE_LINE_PRICE = 6
  const multiLinePrice =
    coupon &&
    prices.some(
      (price) => price.amount.length > MAX_PRICE_DIGITS_FOR_SINGLE_LINE_PRICE
    )

  // Ordered by smallest -> largest  (month > quarter > year)
  const sortedPrices = [...prices].sort(
    (a, b) => a.interval_count - b.interval_count
  )

  const priceWithFrequencyStyles = {
    color: "#718096",
    fontFamily: "inherit",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
    display: multiLinePrice ? "block" : "inline"
  }

  const priceFrequencyStyles = {
    color: "#252B30",
    fontFamily: "inherit",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left"
  }

  if (coupon) {
    return (
      <fieldset className="flex justify-center" style={{ padding: "0" }}>
        <VisuallyHidden>
          <legend>Available prices:</legend>
        </VisuallyHidden>
        {sortedPrices.map((price) => (
          <div
            className="border border-solid rounded"
            style={
              activePrice === price
                ? priceElementStyles.active
                : priceElementStyles.inactive
            }
            key={price.id}
          >
            <label htmlFor={price.id} style={{ cursor: "pointer" }}>
              <div style={priceElementInnerContainerStyles}>
                <span
                  className="block"
                  style={{ ...priceFrequencyStyles, color: textColor }}
                >
                  {frequencyTitle(frequency(price))}
                </span>

                <span style={priceCrossedOutStyles}>
                  {formatPrice(price.amount, price.currency)}
                </span>

                <span style={priceWithFrequencyStyles}>
                  {formatPrice(discountedPrice(price, coupon), price.currency)}
                </span>
              </div>
            </label>
            <input
              id={price.id}
              type="radio"
              checked={activePrice === price}
              onChange={() => selectPrice(price)}
              value={price.id}
              style={{ display: "none" }}
            />
          </div>
        ))}
      </fieldset>
    )
  } else {
    return (
      <fieldset className="flex justify-center" style={{ padding: "0" }}>
        <VisuallyHidden>
          <legend>Available prices:</legend>
        </VisuallyHidden>
        {sortedPrices.map((price) => (
          <div
            key={price.id}
            className="border border-solid rounded"
            style={
              activePrice === price
                ? priceElementStyles.active
                : priceElementStyles.inactive
            }
          >
            <label htmlFor={price.id} style={{ cursor: "pointer" }}>
              <div style={priceElementInnerContainerStyles}>
                <span
                  className="block"
                  style={{ ...priceFrequencyStyles, color: textColor }}
                >
                  {frequencyTitle(frequency(price))}
                </span>

                <span style={priceWithFrequencyStyles}>
                  {formatPrice(price.amount, price.currency)}
                </span>
              </div>
            </label>
            <input
              id={price.id}
              type="radio"
              checked={activePrice === price}
              onChange={() => selectPrice(price)}
              value={price.id}
              style={{ display: "none" }}
            />
          </div>
        ))}
      </fieldset>
    )
  }
}

export default MultiplePrices
