import React from "react"
import { useStyles, usePageContext } from "../landing-page"

const PoweredByConvertKit = () => {
  const { powered_by_url } = usePageContext()
  const { forElement } = useStyles()
  const style = forElement("powered_by")

  return (
    <div className="formkit-powered-by-convertkit-container" style={style}>
      <a
        rel="nofollow"
        href={powered_by_url}
        className="formkit-powered-by-convertkit"
        data-element="powered-by"
        target="_blank"
      >
        Powered By ConvertKit
      </a>
    </div>
  )
}

export default PoweredByConvertKit
