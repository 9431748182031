import React from "react"
import Element from "./element"
import { useElement } from "../landing-page"
import sanitizeHTML, { defaultConfig } from "../../utils/sanitize-html"

function Content({ defaults = { content: "" }, name, ...props }) {
  const element = useElement(name)

  const content = element.content || `<p>${defaults.content}</p>`

  return (
    <Element
      as="div"
      name={name}
      {...props}
      dangerouslySetInnerHTML={{
        __html: sanitizeHTML(content, {
          allowedTags: defaultConfig.allowedTags.concat(["iframe"])
        })
      }}
    />
  )
}

Content.componentType = "Text"

export default Content
