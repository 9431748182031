import React from "react"
import Element from "./element"
import { useElement } from "../landing-page"
import sanitizeHTML from "../../utils/sanitize-html"

const Spinner = () => (
  <div className="formkit-spinner">
    <div />
    <div />
    <div />
  </div>
)

function Button({ defaults, name, as = "button", ...props }) {
  const element = useElement(name)

  const content = element.content || defaults.content

  return (
    <Element as={as} name={name} data-element={name} {...props}>
      <Spinner />
      <span dangerouslySetInnerHTML={{ __html: sanitizeHTML(content) }} />
    </Element>
  )
}

export default Button
