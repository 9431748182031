import React from "react"
import Element from "./element"
import { useElement } from "../landing-page"
import sanitizeHTML from "../../utils/sanitize-html"

function Heading({ tag = "h1", name, defaults, ...props }) {
  const element = useElement(name)
  const content = element.content || `<${tag}>${defaults.content}</${tag}>`

  return (
    <Element
      as="div"
      name={name}
      {...props}
      dangerouslySetInnerHTML={{ __html: sanitizeHTML(content) }}
    />
  )
}

export default Heading
