import React, { useState } from "react"
import PropTypes from "prop-types"
import { formatPrice } from "../../utils/price-functions"

// Why are props here different than in main app?
function PayWhatYouWantInput({ price, selectPrice, styles, textColor }) {
  const [pwywPrice, setPwywPrice] = useState("")

  const minimumPriceFormatted = formatPrice(price.amount, price.currency)

  const setPriceValues = (value, centsAmount) => {
    const userEnteredPrice = {
      id: price.id,
      amount: centsAmount.toString(),
      currency: price.currency,
      formatted: formatPrice(centsAmount, price.currency)
    }

    setPwywPrice(value)
    selectPrice(userEnteredPrice)
  }

  const handleChange = (e) => {
    const value = e.target.value

    if (new RegExp(/^[0-9.]+$/).test(value) === false) {
      setPriceValues(value.replace(/\D/g, ""), price.amount)
      return
    }
    const priceToCents = parseFloat(value) * 100

    setPriceValues(value, priceToCents)
  }

  return (
    <>
      <label
        className="formkit-pwyw-input___label"
        htmlFor="payWhatYouWantPriceInput"
        style={{ color: textColor }}
      >
        Choose how much to pay
      </label>
      <div className="flex-row w-full relative">
        <input
          id="payWhatYouWantPriceInput"
          className="formkit-input formkit-pwyw-input"
          name="pay_what_you_want_price"
          style={styles.forElement("purchase_input")}
          required={true}
          type="text"
          pattern="[0-9]*"
          placeholder={`Enter ${minimumPriceFormatted} or more`}
          value={pwywPrice}
          onChange={handleChange}
        />
        <div className="bg-gray-200 flex formkit-pwyw-input___currency">
          {price.currency_display}
        </div>
      </div>
    </>
  )
}

PayWhatYouWantInput.propTypes = {
  price: PropTypes.object.isRequired,
  selectPrice: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired
}

export default PayWhatYouWantInput
