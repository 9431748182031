import React from "react"
import { useChildElements } from "../landing-page"

import Static from "./static"
import Gallery from "./gallery"

// Apply custom classNames to certain elements.
function addClassNames(type, classNames) {
  const classes = classNames ? classNames : ""
  switch (type) {
    case "Text":
      return `${classes} formkit-text`
    default:
      return classes
  }
}

// Match child elements to the correct components.
const components = {
  Text: () => <Static as="div" />,
  Gallery,
  Links: () => <Static as="div" />,
  Icons: () => <Static as="div" />,
  Feed: () => <Static as="div" />
}

export default function Region({ name, className, group }) {
  const { children } = useChildElements("region", name)

  if (!children.length) {
    console.warn(
      `No child elements were detected in "${name}", so it will be rendered as static. This can be caused by a page build, so it may resolve itself within minutes. If not, the page might need to be rebuilt.`
    )
    return <Static as="div" />
  }

  return (
    <div className={className}>
      {children.map((element) => {
        const Component = components[element.type]
        const classNames = addClassNames(element.type, element.className)
        return (
          <Component
            key={element.element_id}
            type={element.type}
            group={group}
            {...element}
            className={classNames}
          />
        )
      })}
    </div>
  )
}
