import React, { useContext } from "react"
import ReactDOM from "react-dom"
import stylesToJs from "../utils/styles-to-js"
import addStyles from "../utils/add-styles"
import "./elements"

const LandingPageContext = React.createContext({})

export function useInfo() {
  return useContext(LandingPageContext)
}

export function useSettings() {
  return useContext(LandingPageContext).settings
}

export function useStyles() {
  const { styles } = useContext(LandingPageContext)

  const forElement = (name, defaults) => {
    const allStyles = addStyles(name, styles)
    return stylesToJs(name, allStyles, defaults)
  }

  return { forElement }
}

export function useElement(name) {
  const context = useContext(LandingPageContext)

  // COMPAT: IE11 does not support `.find`, so we use `.filter`
  return context.elements.filter((element) => element.name === name)[0] || {}
}

const custom = (element) => element.type === "Custom"
const inputs = (element) => element.type === "Input" || custom(element)

export const useElements = () => {
  const { elements } = useContext(LandingPageContext)

  return {
    all: elements,
    custom: elements.filter(custom),
    inputs: elements.filter(inputs)
  }
}

export function useChildElements(key, value) {
  const { all } = useElements()

  // Finds and orders all non-deleted elements with a `key` matching `value`.
  let children
  if (!key || !value) {
    children = []
  } else {
    children = all
      .filter((el) => el[key] === value && !el._delete)
      .sort((a, b) => a.order - b.order)
  }

  return { children }
}

export const usePageContext = () => {
  const { context } = useContext(LandingPageContext)
  return context
}

function Wrapper({ children, context }) {
  const styles = useStyles()
  const elements = useElements()

  return React.cloneElement(children, { elements, styles, context })
}

function LandingPage({
  id,
  uid,
  action,
  children,
  elements = [],
  settings = [],
  styles = [],
  context = {}
}) {
  return (
    <LandingPageContext.Provider
      value={{ id, uid, action, elements, settings, styles, context }}
    >
      <Wrapper context={context}>{children}</Wrapper>
    </LandingPageContext.Provider>
  )
}

if (document && document.body.dataset.template) {
  const template = document.body.dataset.template
  const Template = window.__templates[template]
  const node = document.body.querySelector("#container")

  const props = window.__props || { elements: [], settings: [], styles: [] }

  ReactDOM.hydrate(
    <LandingPage {...props}>
      <Template />
    </LandingPage>,
    node
  )
}

export default LandingPage
