import React from "react"
import { useStyles } from "../landing-page"
import {
  formatPrice,
  discountedPrice,
  frequency,
  formatInstallmentsDuration,
  formatInstallmentsPrice
} from "../../utils/price-functions"

function Price({ price, coupon, ...props }) {
  const styles = useStyles()
  const css = styles.forElement("price")

  if (coupon) {
    return (
      <div style={css} {...props}>
        <span
          style={{
            textDecoration: "line-through",
            fontSize: "0.9rem",
            opacity: "0.6"
          }}
        >
          {formatPrice(price.amount, price.currency)}
        </span>
        {price.installments && (
          <span style={{ marginLeft: "0.25rem" }}>
            {formatInstallmentsDuration(price, coupon)}
            <div
              style={{
                fontSize: "1.2rem",
                opacity: "0.6",
                marginBottom: "15px"
              }}
            >
              {formatInstallmentsPrice(price, coupon)}
            </div>
          </span>
        )}

        {!price.installments && (
          <span style={{ marginLeft: "0.25rem" }}>
            {formatPrice(discountedPrice(price, coupon), price.currency)}
            {price.interval ? ` / ${frequency(price)}` : null}
          </span>
        )}
      </div>
    )
  }

  if (price.installments) {
    return (
      <div style={css} {...props}>
        {formatInstallmentsDuration(price)}
        <div
          style={{ fontSize: "1.2rem", opacity: "0.6", marginBottom: "15px" }}
        >
          {formatInstallmentsPrice(price)}
        </div>
      </div>
    )
  }

  return (
    <div style={css} {...props}>
      {formatPrice(price.amount, price.currency)}
      {price.interval ? ` / ${frequency(price)}` : null}
    </div>
  )
}

export default Price
