import React from "react"
import { useSettings, useStyles } from "../landing-page"

const BuiltWith = (props) => {
  const { variant } = props

  const { powered_by = {} } = useSettings()
  const { show, url } = powered_by
  const style = show ? {} : { display: "none" }

  const { forElement } = useStyles()
  const background = forElement(props.background).backgroundColor
  const image = forElement(props.image).backgroundImage
  const imageOpacity = forElement(props.image).opacity
  const opacityThreshold = 0.6
  let renderColor = "block"

  if (background) {
    if (image && imageOpacity) {
      const isWithinOpacityThreshold =
        Math.fround(parseFloat(imageOpacity)) < Math.fround(opacityThreshold)

      if (isWithinOpacityThreshold) {
        renderColor = isBackgroundLight(background) ? "dark" : "light"
      }
    } else {
      renderColor = isBackgroundLight(background) ? "dark" : "light"
    }
  }

  const renderVariant = variant ? variant : renderColor

  return (
    <div className="formkit-powered-by-convertkit-container" style={style}>
      <a
        href={url}
        rel="nofollow"
        className="formkit-powered-by-convertkit"
        data-element="powered-by"
        data-variant={renderVariant}
        target="_blank"
      >
        Powered By ConvertKit
      </a>
    </div>
  )
}

export default BuiltWith

const isBackgroundLight = (background) => {
  let r, g, b, hsp, color

  if (background.match(/^rgb/)) {
    color = background.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    )

    r = color[1]
    g = color[2]
    b = color[3]
  } else {
    color = +(
      "0x" + background.slice(1).replace(background.length < 5 && /./g, "$&$&")
    )

    r = color >> 16
    g = (color >> 8) & 255
    b = color & 255
  }

  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  if (hsp > 127.5) {
    return true
  } else {
    return false
  }
}
