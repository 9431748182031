import React from "react";

function Errors() {
  // TODO: Pull errors from state
  const errors = [];

  return (
    <ul
      className="formkit-alert formkit-alert-error"
      data-element="errors"
      data-group="alert"
    >
      {errors.map(error => (
        <li key={error}>{error}</li>
      ))}
    </ul>
  );
}

export default Errors;
