import React from "react";
import { useElement } from "../landing-page";

const getVideoId = link => {
  try {
    if (link.includes("youtube.com/watch?")) {
      return link.split("/watch?v=").pop();
    } else if (link.includes("youtu.be")) {
      return link.split("/").pop();
    }
  } catch (err) {
    return null;
  }
};

function Video({ name, className, ...props }) {
  const element = useElement(name);
  const src = element.src;

  if (!src) return null;

  const videoId = getVideoId(src);

  return (
    <div className={`formkit-video ${className}`} {...props}>
      <div className="formkit-video-frame" style={{ position: "relative", width: "100%", paddingTop: "56.25%"}}>
        <iframe
          width="2560"
          height="1440"
          style={{position: "absolute", width: "100%", height: "100%", left: "0px", top: "0px"}}
          src={
            "https://www.youtube.com/embed/" +
            videoId +
            "?autoplay=0&color=white&modestbranding=1&rel=0"
          }
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
}

export default Video;
